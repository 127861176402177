<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">Something went wrong</h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>Please make sure your MetaMask account is connected and reload this page</p>
      </div>
      <ButtonButton
        type="button"
        class="block w-full mt-12"
        @click.prevent.stop="reload()"
      >
        Reload
      </ButtonButton>
    </div>
  </article>
</template>

<script>
export default defineComponent({
  name: 'ModalErrorContentLoginFailed',
  methods: {
    reload() {
      window.location.reload();
    },
  },
});
</script>
