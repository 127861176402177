<template>
  <article class="animate-fade-in">
    <div class="text-center flex justify-center flex-col items-center">
      <img src="~/assets/img/logos/MetaWin-logo-white.svg" class="w-60 mb-5">
      <div class="mb-1">Sorry, MetaWin isn't available in your location.</div>
      <div class="text-sm text-slate-200">Due to our gaming licence, we cannot accept players from your location. Go to <NuxtLink class="text-[#00D1FF]" to="https://discord.gg/the-arena" target="_blank">The ARENA Discord</NuxtLink> if you require further assistance.</div>
    </div>
  </article>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useUiStore } from '@/store/ui';

export default defineComponent({
  name: 'ModalErrorContentLocationRestricted',
  emits: [
    'close',
  ],
  computed: {
    ...mapWritableState(useUiStore, ['errorModalData',]),
  },
  methods: {
    close() {
      this.$emit('close');
      this.errorModalData = null;
    },
  },
});
</script>
